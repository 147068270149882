/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFetchFamilyDataHandler } from './apiHandlers/useFetchDataHandler';
import DestinationServicesCrudTable from './crudTables/destinationServicesCrud.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import InternationalSchoolingCrudTable from './crudTables/internationalSchoolingCrud.component';
import LanguageTrainingCrudTable from './crudTables/languageTrainingCrud.component';
import React from 'react';
import SummaryCard from '../../summaryCard.component';

export const GlobalDestinationServices = ({ details, id, isReadOnly }) => {

  //TODO - remove this when familt is ready
  const getFamilyhandler = useFetchFamilyDataHandler();
  const familyMembers = getFamilyhandler.fetchData();

  const {isLoading } = useSelector(
    (state) => {
      return {
        isLoading: state.authorizations.isLoading,
      };
    },
  );


  return (
    <>
      {isLoading ? (
        <FullscreenSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ padding: '20px' }}>
            <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <DestinationServicesCrudTable id={id} isReadOnly={isReadOnly}/>
              <InternationalSchoolingCrudTable id={id} family={familyMembers} isReadOnly={isReadOnly}/>
              <LanguageTrainingCrudTable id={id} family={familyMembers} isReadOnly={isReadOnly}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ position: 'sticky', top: 180, height: 'fit-content' }}>
                <SummaryCard dateSummary={details?.authDetails?.dateSummary} id={id} hideEdit={true} />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default GlobalDestinationServices;

/* eslint-disable react/prop-types */
import * as Sentry from '@sentry/browser';
import { Badge, Button, Fab, Fade, FormControlLabel, Paper, Switch, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, yupToFormErrors } from 'formik';
import { Prompt } from 'react-router-dom';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { isObject } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import queryString from 'query-string';

import { GET_POLICY_SUMMARIES_BY_CLIENT_FAILURE } from 'modules/selfService/types';
import { InputListener } from 'modules/intake/form';
import { IntakeRecord } from 'modules/intake/models';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  clearIntakeRecordValidation,
  getTempIntakeFormDetails,
  setEEIntakeStatus,
  submitIntakeRecord,
  submitPncLead,
  submitPremiaLead,
  submitRocketLead,
  submitVendorLetter,
  validateIntakeRecord,
} from 'modules/intake/intake.actions';
import { getPolicySummariesByClientId } from 'modules/selfService/selfService.actions';
import { hideToast, setPageTitle, showToast } from 'modules/layout/layout.actions';
import { isEmptyDeep } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import DownloadIntakePdfDialog from './downloadIntakePdfDialog.component';
import Log from 'utilities/log';
import NotesDrawer from 'modules/common/notesDrawer.component';
import Spinner from 'modules/common/spinner.component';
import SubmitIntakeDialog from './submitIntakeDialog.component';
import auth from 'auth/auth';

import {
  AUTHORIZATION_COMMENTS,
  AUTHORIZATION_INTAKE,
  BENEFITS_COUNSELING,
  HEADER_HEIGHT,
  HEADER_HEIGHT_SHRINK,
  SUMMARY,
  TAB_ORDER,
  ValidationMode,
} from '../constants';
import {
  GET_INTAKE_FORM_METADATA_FAILURE,
  GET_INTAKE_RECORD_FAILURE,
  SUBMIT_INTAKE_RECORD_FAILURE,
  SUBMIT_INTAKE_RECORD_SUCCESS,
  SUBMIT_PNC_LEAD_FAILURE,
  SUBMIT_PREMIA_LEAD_FAILURE,
  SUBMIT_ROCKET_LEAD_FAILURE,
  UPDATE_INTAKE_RECORD_FAILURE,
  UPDATE_INTAKE_RECORD_SUCCESS,
  VALIDATE_INTAKE_RECORD_FAILURE,
} from '../types';
import {
  clearFocusedGroup,
  getIntakeFormMetadata,
  getIntakeRecord,
  setFocusedGroup,
  setFocusedTab,
  updateIntakeRecord,
} from '../intake.actions';
import { mortgageLeadsAreValid, parsePncPermiaInfo, parseRocketInfo, remapMetadata, remapValidationResults } from '../utils';
import AuthorizationCommentsForm from './authorizationComments/authorizationCommentsForm.container';
import AuthorizationIntakeForm from './authorizationIntake/authorizationIntakeForm.component';
import BenefitsCounselingForm from './benefits/benefitsCounselingForm.component';
import IntakeRecordHeader from './intakeRecordHeader.component';
import Summary from './summary/summary.container';

const styles = (theme) => ({
  actionButtonContainer: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    top: 0,
    right: 0,
    height: 64,
    display: 'flex',
    alignItems: 'center',
  },
  badgePadding: {
    padding: theme.spacing(0, 2),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  form: {
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  invalidIcon: {
    marginLeft: theme.spacing(0.5),
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  savedMessage: {
    color: theme.palette.common.white,
  },
  tabsRoot: {
    paddingTop: HEADER_HEIGHT,
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    zIndex: 1,
  },
  shrinkTabs: {
    paddingTop: HEADER_HEIGHT_SHRINK,
  },
  tabContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(23),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tabContainerAlt: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(23),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[50],
  },
  notesButtonContainer: {
    position: 'fixed',
    right: theme.spacing(.75),
    top:HEADER_HEIGHT - 80,
    zIndex: 1500,
    paddingTop: theme.spacing(8),
  },

  container: {
    height: 'auto',
    minHeight: HEADER_HEIGHT_SHRINK,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    zIndex: theme.zIndex.appBar,
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },

});

function IntakeRecordDetailsView(props){
  const [initialValues, setInitialValues] = useState(null);
  const [initialErrors, setInitialErrors] = useState(null);
  const [initialTouched, setInitialTouched] = useState(null);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  const [failedToLoadRecord, setFailedToLoadRecord] = useState(false);
  const [failedToLoadMetadata, setFailedToLoadMetadata] = useState(false);
  const [failedToSubmitRecord, setFailedToSubmitRecord] = useState(false);
  const [showSubmitConfirmationDialog, setShowSubmitConfirmationDialog] = useState(false);
  const [showDownloadPdfDialog, setShowDownloadPdfDialog] = useState(false);
  const [showSubmitSuccessfulDialog, setShowSubmitSuccessfulDialog] = useState(false);
  const [bankingInfoRequired, setBankingInfoRequired] = useState(true);
  const [reloadData, setReloadData] = useState(true);
  const [failedPrimaryValidation, setFailedPrimaryValidation] = useState(false);
  const [selfServicePolicySummaries, setSelfServicePolicySummaries] = useState(false);
  const [showNotesSideDrawer, setShowNotesSideDrawer] = useState(false);
  const [finalSubmissionValues, setFinalSubmissionValues] = useState(null);
  const dispatch = useDispatch();

  const { location, setFocusedTab: propsSetFocusedTab, setPageTitle: propsSetPageTitle } = props;

  const formRef = useRef();

  //initial load of form
  useEffect(() => {
    propsSetPageTitle('Intake Record');

    const { tab } = queryString.parse(location.search);
    if (tab) {
      propsSetFocusedTab(tab);
    } else {
      propsSetFocusedTab(AUTHORIZATION_INTAKE);
    }
  }, [propsSetPageTitle, propsSetFocusedTab, location]);

  //reload data
  useEffect(() => { 

    if (reloadData) {
      setReloadData(false);
      loadData();
    }
    async function loadData() {
      props.hideToast();

      const id = + props.match.params.id;

      setFailedToLoadRecord(false);
      let resp = await props.getIntakeRecord(id);
      if (resp.type === GET_INTAKE_RECORD_FAILURE) {
        props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setFailedToLoadRecord(true);
        return;
      }
      let intakeRecord = resp.response;

      let { metadata } = props;
      if (!metadata) {
        setFailedToLoadMetadata(false);
        let metadataResp = await props.getIntakeFormMetadata();
        if (metadataResp.type === GET_INTAKE_FORM_METADATA_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setFailedToLoadMetadata(true);
          return;
        }
        metadata = remapMetadata(metadataResp.response);
      }
      if (auth.userHasPermission('selfService:read:policies'))
      {
        let policyResp = await dispatch(getPolicySummariesByClientId(intakeRecord.client.moveTrackClientId));
        if (resp.type === GET_POLICY_SUMMARIES_BY_CLIENT_FAILURE) {
          props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setFailedToLoadRecord(true);
          return;
        }

        setSelfServicePolicySummaries(policyResp.response);
      }
      else {
        setSelfServicePolicySummaries([]);
      }

      let vals = await IntakeRecord.fromWireData(intakeRecord, metadata);
      setInitialValues(vals);

      if (intakeRecord.transfereeIntakeRequired) await props.getTempIntakeFormDetails(intakeRecord.id);
    }
    
  }, [reloadData, props, dispatch]);

  const handleSectionFocus = (section) => {
    if (section) {
      handleTabChange(section.tab);
      props.setFocusedGroup(section.group);
    }
  };

  const handleTabChange = (tab) => {
    props.setFocusedTab(tab);

    const values = queryString.parse(props.location.search);
    values.tab = tab;
    props.history.replace(`${props.location.pathname}?${queryString.stringify(values)}`);
  };

  const handleChange = async (formikProps) => {
    const { metadata } = props;
    if (formikProps.dirty && !formikProps.isSubmitting) {
      setActiveElement(document.activeElement);
      if (props.focusedTab !== SUMMARY)
      {
        formikProps.submitForm();
      }
      else
      {
        let resp = await save(formikProps.values);
        if (resp.type === UPDATE_INTAKE_RECORD_SUCCESS) {
          const newValues = await IntakeRecord.fromWireData(resp.response, metadata);
          setInitialValues(newValues);
        }
      }
    } else {
      Log.trace(
        `Not saving right now: props.dirty:${formikProps.dirty} props.isSubmitting: ${formikProps.isSubmitting} ${JSON.stringify(formikProps.errors)}`,
      );
      Log.trace(formikProps.values);
    }
  };

  const handleSaveRequest = async (values, { setSubmitting }) => {
    Log.trace(values, 'IntakeRecordDetails');
    const { metadata } = props;
    Sentry.setExtra('intakeRecord', values);
    
    let resp = await save(values);
    if (resp.type === UPDATE_INTAKE_RECORD_SUCCESS) {
      const newValues = await IntakeRecord.fromWireData(resp.response, metadata);
      setInitialValues(newValues);
    }

    setSubmitting(false);
    return resp.type === UPDATE_INTAKE_RECORD_SUCCESS;
  };

  // Autosave Function
  const save = async (intakeRecordInstance) => {

    const { match, intakeRecords } = props;
    Log.trace(intakeRecordInstance, 'Autosave');

    let showSavedMessageTimeout = null;

    clearTimeout(showSavedMessageTimeout);

    const edittedData = await intakeRecordInstance.toWireData();
    const id = +match.params.id;
    const intakeRecord = intakeRecords[id];

    const resp = await props.updateIntakeRecord({ ...intakeRecord, ...edittedData });

    if (resp.type === UPDATE_INTAKE_RECORD_FAILURE) {
      props.showToast('Failed to save data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setShowSavedMessage(true);

      showSavedMessageTimeout = setTimeout(() => {
        setShowSavedMessage(false);
      }, 3000);

      if (activeElement) {
        setTimeout(() => activeElement.focus());
      }

      if (intakeRecord.transfereeIntakeRequired) await props.getTempIntakeFormDetails(intakeRecord.id);
    }

    return resp;
  };

  const getCommentsLabel = (comments) => {
    const { classes } = props;
    const tabLabel = 'Authorization Comments';
    if (comments.length > 0) {
      return (
        <Badge className={classes.badgePadding} color="secondary" badgeContent="!">
          {tabLabel}
        </Badge>
      );
    }

    return tabLabel;
  };

  const changeTab = (offset) => {
    const { focusedTab } = props;
    const currentIndex = TAB_ORDER.indexOf(focusedTab);
    const tab = TAB_ORDER[currentIndex + offset];
    handleTabChange(tab);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const validate = async (intakeRecord) => {
    const { metadata } = props;

    // validate against local schema before attempting to save data
    try {
      await IntakeRecord.schema.validate(intakeRecord, { abortEarly: false });
    } catch (err) {
      setFailedPrimaryValidation(true);
      return yupToFormErrors(err);
    }

    setFailedPrimaryValidation(false);


    // data needs to be saved for API to validate record
    const updateResponse = await save(intakeRecord);
    if (updateResponse.type === UPDATE_INTAKE_RECORD_FAILURE) {
      props.showToast('Failed to save data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
    
    const validateResponse = await props.validateIntakeRecord(intakeRecord);
    if (validateResponse.type === VALIDATE_INTAKE_RECORD_FAILURE) {
      props.showToast('Failed to validate record, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }

    const remappedResults = remapValidationResults(validateResponse.response);
    let errors = IntakeRecord.generateErrors(remappedResults);
    if (isEmptyDeep(errors)) errors = {};

    const newValues = await IntakeRecord.fromWireData(updateResponse.response, metadata);
    setInitialValues(newValues);

    setInitialErrors(errors);

    const touchedFields = errorsToTouchedFields(errors);
    setInitialTouched(touchedFields);

    Log.trace(errors, 'AsyncValidator');

    return errors;
  };

  const errorsToTouchedFields = (obj) => {
    let result = {};
    for (let [key, value] of Object.entries(obj)) {
      if (isObject(value))
        result[key] = errorsToTouchedFields(value);
      else
        result[key] = value !== null;
    }

    return result;
  };


  const submit = async () => {
    const updateResponse = await save(finalSubmissionValues);
    if (updateResponse.type === UPDATE_INTAKE_RECORD_FAILURE) {
      props.showToast('Failed to save data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }

    const intakeRecord = updateResponse.response;
    const id = +props.match.params.id;
    
    const submitResponse = await props.submitIntakeRecord(id, false, bankingInfoRequired);
    if (submitResponse.type === SUBMIT_INTAKE_RECORD_FAILURE) {
      setShowSubmitConfirmationDialog(false);
      setFailedToSubmitRecord(false);
      props.showToast('Failed to submit record, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } else if (submitResponse.type === SUBMIT_INTAKE_RECORD_SUCCESS) {
      // Gather mortgage lead info
      if (intakeRecord && intakeRecord.moveTypeId === 1 && (intakeRecord.mortgageLeadPncSelected || intakeRecord.mortgageLeadPremiaSelected || intakeRecord.mortgageLeadRocketSelected)) {
        let intakeSummary = props.intakeSummaries?.find((o) => o.intakeRecordId === id);
        if (intakeSummary && initialValues) {
          let policyCode = initialValues.primaryInformation?.relocationPolicyCodes?.find((o) => o.id === intakeSummary.relocationPolicyCodeId)?.policyCode;
          // Submit Rocket Mortgage lead
          if (intakeRecord.mortgageLeadRocketSelected) {
            let parsedRocketInfo = parseRocketInfo(intakeRecord, intakeSummary.relocationConsultant, policyCode);
            const submitRocketLeadResp = await props.submitRocketLead(parsedRocketInfo, intakeRecord.clientId);
            if (submitRocketLeadResp.type === SUBMIT_ROCKET_LEAD_FAILURE) {
              props.showToast('Failed to send Rocket Mortgage lead.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
          }

          // Get PNC and Premia info ready
          let parsedPncPermiaInfo = parsePncPermiaInfo(intakeRecord);

          // Submit PNC Mortgage lead
          if (intakeRecord.mortgageLeadPncSelected) {
            const submitPncLeadResp = await props.submitPncLead(parsedPncPermiaInfo, intakeRecord.clientId);
            if (submitPncLeadResp.type === SUBMIT_PNC_LEAD_FAILURE) {
              props.showToast('Failed to send PNC mortgage lead.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
          }

          // Submit Premia lead
          if (intakeRecord.mortgageLeadPremiaSelected) {
            const submitPremiaResp = await props.submitPremiaLead(parsedPncPermiaInfo, intakeRecord.clientId);
            if (submitPremiaResp.type === SUBMIT_PREMIA_LEAD_FAILURE) {
              props.showToast('Failed to send Premia mortgage lead.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            }
          }
        }       
        
        // Submit vendor letters
        if (intakeRecord.vendorLetters !== undefined && intakeRecord.vendorLetters !== '[]'){
          let jsonVendorLetters = JSON.parse(intakeRecord.vendorLetters);
          jsonVendorLetters.forEach(async (a) =>{
            await props.submitVendorLetter(intakeRecord.mtAuthorizationId, a, intakeRecord.clientId);
          });
        }
      }    

      // Disable the questionare if it is currently active
      if (intakeRecord.transfereeIntakeRequired) {
        props.setEEIntakeStatus(id, false);
      }
      
      setShowSubmitConfirmationDialog(false);
      setShowSubmitSuccessfulDialog(true);
    }
    setShowSubmitConfirmationDialog(false);
    setShowSubmitSuccessfulDialog(true);
  };

  const handleChangeBankingInfoRequired = (event, value) => {
    setBankingInfoRequired(value);
  };

  const mortgageLeadInfoIsValid = (mortgageVendors, moveTypeId) => {
    if (!mortgageLeadsAreValid(mortgageVendors, moveTypeId)) {
      return false;
    } else {
      return true;
    }
  };

  const submitDisabled = (mortgageVendors, moveTypeId) => {
    if (initialValues)
    {
      if (initialValues.newDestination.isDestinationServiceInitiated && (initialValues.newDestination.isRenting || initialValues.newDestination.isPurchasing))
      {
        if (moveTypeId === 7)
        {
          return false;
        }
        else if (mortgageLeadInfoIsValid(mortgageVendors, moveTypeId))
        {
          return false; 
        }
        else
        {
          return true;
        }
      } else {
        if (moveTypeId === 7)
        {
          return false;
        }
        else if (mortgageLeadInfoIsValid(mortgageVendors, moveTypeId))
        {
          return false; 
        }
        else
        {
          return true;
        }
      }
    }
    else
    {
      return true;
    }
  };

  const isStrictValidation = props.validationMode === ValidationMode.Strict;
  const userCanSubmit = auth.userHasPermission('submit');

  if (failedToLoadRecord || failedToLoadMetadata || failedToSubmitRecord) {
    return (
      <div className="center-container">
        <Typography variant="h3" color="error" gutterBottom>
          Something went wrong
        </Typography>
        <Button size="large" onClick={() => { setReloadData(true); setInitialValues(null); }} variant="contained" color="primary">
          Try Again
        </Button>
      </div>
    );
  }
  else {

    const id = +props.match.params.id;
    const intakeRecord = props.intakeRecords[id];

    return (

      (!initialValues || !selfServicePolicySummaries) ? 
        <div className="center-container">
          <Spinner logo />
        </div>
        :
        <Fragment>
          <div className={props.classes.notesButtonContainer}>
            <Fab color="primary" onClick={() => {setShowNotesSideDrawer(true);}}>
              <div className="column align-center">
                <FontAwesomeIcon icon={['far', 'plus']} color={props.theme.palette.primary.contrastText} />
                Notes
              </div>
            </Fab>
          </div>
                  
          <ConfirmationDialog
            onConfirm={() => {
              props.history.replace('/intakeRecords');
            }}
            onCancel={() => {}}
            open={initialValues.primaryInformation.intakeRecordStatusId !== 0}
            title="Record Submitted"
            confirmLabel="Ok"
            hideCancel={true}
          >
            <Typography>This record has been previously submitted and can no longer be edited.</Typography>
          </ConfirmationDialog>
  
          <Formik
            initialValues={initialValues}
            initialErrors={initialErrors}
            initialTouched={initialTouched}
            validationSchema={isStrictValidation ? undefined : IntakeRecord.schema}
            validate={isStrictValidation ? validate : undefined}
            onSubmit={handleSaveRequest}
            enableReinitialize
            validateOnBlur={!isStrictValidation}
            validateOnChange={!isStrictValidation}
            innerRef={formRef}
          >
            {(formikProps) => (
              <form noValidate autoComplete="off" className={props.classes.form}>
                <Prompt
                  when={formikProps.dirty}
                  message={({ pathname }) => {
                    return pathname === props.location.pathname || 'You have unsaved changes, are you sure you want to leave?';
                  }}
                />
                <div className={props.classes.actionButtonContainer}>
                  <Fade in={showSavedMessage}>
                    <Typography className={props.classes.savedMessage}>Saved!</Typography>
                  </Fade>
                  <Button
                    variant="contained"
                    className={props.classes.button}
                    disabled={formikProps.isSubmitting || formikProps.isValidating}
                    onClick={formikProps.submitForm}
                  >
                    {formikProps.isSubmitting ? <Spinner size="lg" color="#fff" /> : 'Save'}
                    {!isEmptyDeep(formikProps.errors) && (
                      <FontAwesomeIcon
                        color={props.theme.palette.error.main}
                        icon={['fas', 'exclamation-circle']}
                        size="lg"
                        className={props.classes.invalidIcon}
                      />
                    )}
                  </Button>
                </div>
                <InputListener onChange={handleChange.bind(this, formikProps)} submitForm={formikProps.submitForm}>
                  <IntakeRecordHeader
                    {...formikProps}
                    eeIntakeForm={intakeRecord.eeIntakeForm}
                    primaryInformation={formikProps.values.primaryInformation}
                    departureAddress={formikProps.values.departureProperty.address}
                  />
                  <Paper square className={classNames('position-fixed', props.classes.container, { [props.classes.drawerOpen]: false })}>
                    <Tabs
                      variant="fullWidth"
                      value={props.focusedTab}
                      onChange={(event, tab) => handleTabChange(tab)}
                      classes={{
                        root: classNames(props.classes.tabsRoot, { [props.classes.shrinkTabs]: !formikProps.values.primaryInformation.relocationDistance }),
                      }}
                    >
                      <Tab value={AUTHORIZATION_INTAKE} label="Authorization Intake" />
                      <Tab value={BENEFITS_COUNSELING} label="Benefits Counseling & Timeline" />
                      <Tab value={AUTHORIZATION_COMMENTS} label={getCommentsLabel(formikProps.values.authorizationComments)} />
                      <Tab value={SUMMARY} label="Summary" />
                    </Tabs>
                  </Paper>

                  <NotesDrawer
                    open={showNotesSideDrawer}
                    onClose={() => {setShowNotesSideDrawer(false);}}
                    notes={formikProps.values.notes}
                    saveNote={(note) => {
                      formikProps.setFieldValue('notes', [...formikProps.values.notes, note], false);
                      // Wait until Formik acknowledges the update to form values, then trigger form submission
                      setTimeout(() => {
                        formikProps.submitForm();
                      }, 0);
                    }}
                    {...formikProps}
                  />
                  {props.focusedTab === AUTHORIZATION_INTAKE && (
                    <div className={props.classes.tabContainer}>
                      <AuthorizationIntakeForm
                        {...formikProps}
                        metadata={props.metadata}
                        focusedGroup={props.focusedGroup}
                        clearFocusedGroup={props.clearFocusedGroup}
                        selfServicePolicySummaries={selfServicePolicySummaries}
                      />
                    </div>
                  )}
                  {props.focusedTab === BENEFITS_COUNSELING && (
                    <div className={props.classes.tabContainer}>
                      <BenefitsCounselingForm
                        {...formikProps}
                        metadata={props.metadata}
                        focusedGroup={props.focusedGroup}
                        onSectionFocus={handleSectionFocus}
                        clearFocusedGroup={props.clearFocusedGroup}
                      />
                    </div>
                  )}
                  {props.focusedTab === AUTHORIZATION_COMMENTS && (
                    <div className={props.classes.tabContainerAlt}>
                      <AuthorizationCommentsForm
                        {...formikProps}
                        metadata={props.metadata}
                      />
                    </div>
                  )}
                  {props.focusedTab === SUMMARY && (
                    <div className={props.classes.tabContainerAlt}>
                      <Summary
                        handleChange={formikProps.handleChange}
                        handleBlur={formikProps.handleBlur}
                        onSectionFocus={handleSectionFocus}
                        submitForm={formikProps.submitForm}
                        isValid={formikProps.isValid && mortgageLeadInfoIsValid(formikProps.values.mortgageVendors, formikProps.values?.authorizationInformation?.moveTypeId)}
                        failedPrimaryValidation={failedPrimaryValidation}
                        isValidating={formikProps.isValidating || formikProps.isSubmitting}
                        errors={formikProps.errors}
                        moveTypeId={formikProps.values?.authorizationInformation?.moveTypeId}
                        mortgageVendors={formikProps.values.mortgageVendors}
                        vendorLetters={formikProps.values.vendorLetters}
                        validationResults={props.validationResults}
                        hhgInitiated={initialValues.hhgInformation.isHhgServiceInitiated}
                        tempLivingInitiated={initialValues.temporaryLiving.isTemporaryLivingServiceInitiated}
                        newDestination={initialValues.newDestination}
                      />
                    </div>
                  )}
                </InputListener>
                <div className={props.classes.navContainer}>
                  <div>
                    <Button
                      className={props.classes.button}
                      variant="outlined"
                      size="large"
                      onClick={() => changeTab(-1)}
                      disabled={TAB_ORDER.indexOf(props.focusedTab) === 0}
                    >
                      Previous
                    </Button>
                    <Button
                      className={props.classes.button}
                      variant="outlined"
                      size="large"
                      onClick={() => changeTab(1)}
                      disabled={TAB_ORDER.indexOf(props.focusedTab) === TAB_ORDER.length - 1}
                    >
                      Next
                    </Button>
                  </div>
                  {props.focusedTab === SUMMARY && (
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => {
                        setShowDownloadPdfDialog(true);
                      }}
                      disabled={!formikProps.isValid || formikProps.isSubmitting || formikProps.isValidating || !initialValues}
                    >
                      Save as PDF
                    </Button>
                  )}
                  {props.focusedTab === SUMMARY && userCanSubmit && (
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={bankingInfoRequired}
                            disabled={!formikProps.isValid || formikProps.isSubmitting || formikProps.isValidating || !initialValues}
                            onChange={handleChangeBankingInfoRequired}
                            value={bankingInfoRequired}
                          />
                        }
                        label="Banking Info Required For Expenses"
                      />
                      {/* <FormControlLabel
                        control={
                          <Switch
                            checked={hasWebAccess}
                            disabled={!formikProps.isValid || formikProps.isSubmitting || formikProps.isValidating || !initialValues}
                            onChange={handleChangeHasWebAccess}
                            value={hasWebAccess}
                          />
                        }
                        label="Grant Web Access"
                      /> */}
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => { setShowSubmitConfirmationDialog(true); setFinalSubmissionValues(formikProps.values); }}
                        disabled={!formikProps.isValid || formikProps.isSubmitting || formikProps.isValidating || submitDisabled(formikProps.values.mortgageVendors, formikProps.values?.authorizationInformation?.moveTypeId)}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>

          <SubmitIntakeDialog
            onSubmit={submit}
            onCancel={() => { setShowSubmitConfirmationDialog(false); }}
            isSubmitting={props.isSubmitting}
            open={showSubmitConfirmationDialog}
          />
  
          {showDownloadPdfDialog && (
            <DownloadIntakePdfDialog
              onCancel={() => {setShowDownloadPdfDialog(false);}}
              open={showDownloadPdfDialog}
              intakeRecordId={initialValues.id}
              authorizationId={initialValues.authorizationInformation.mtAuthorizationId}
            />
          )}
  
          <ConfirmationDialog
            onConfirm={() => {
              props.history.replace('/intakeRecords');
            }}
            open={showSubmitSuccessfulDialog}
            title="Record Submitted Successfully"
            confirmLabel="Ok"
            hideCancel={true}
            onCancel={() => {}}
          >
            <Typography>Your record was successfully submitted to MoveTrack.</Typography>
          </ConfirmationDialog>
        </Fragment>
    );
  }
  
}

IntakeRecordDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  intakeRecords: PropTypes.object.isRequired,
  intakeSummaries: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  getIntakeRecord: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  hideToast: PropTypes.func.isRequired,
  getIntakeFormMetadata: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  updateIntakeRecord: PropTypes.func.isRequired,
  setFocusedTab: PropTypes.func.isRequired,
  setFocusedGroup: PropTypes.func.isRequired,
  clearFocusedGroup: PropTypes.func.isRequired,
  focusedTab: PropTypes.string,
  focusedGroup: PropTypes.string,
  theme: PropTypes.object,
  clearIntakeRecordValidation: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  validationResults: PropTypes.array,
  validationMode: PropTypes.oneOf(Object.values(ValidationMode)),
  validateIntakeRecord: PropTypes.func.isRequired,
  submitIntakeRecord: PropTypes.func.isRequired,
  submitRocketLead: PropTypes.func.isRequired,
  submitPncLead: PropTypes.func.isRequired,
  submitPremiaLead: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    intakeRecords,
    intakeSummaries,
    isLoading,
    metadata,
    focusedTab,
    focusedGroup,
    validationResults,
    validationMode,
    isSubmitting,
  } = state.intake;

  return {
    intakeRecords,
    intakeSummaries,
    isLoading,
    metadata,
    focusedTab,
    focusedGroup,
    validationResults,
    validationMode,
    isSubmitting,
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  withTheme,
  connect(mapStateToProps, {
    setPageTitle,
    getIntakeRecord,
    getTempIntakeFormDetails,
    showToast,
    hideToast,
    getIntakeFormMetadata,
    updateIntakeRecord,
    setFocusedTab,
    setFocusedGroup,
    clearFocusedGroup,
    clearIntakeRecordValidation,
    validateIntakeRecord,
    submitIntakeRecord,
    submitRocketLead,
    submitPremiaLead,
    submitPncLead,
    submitVendorLetter,
    setEEIntakeStatus,
  }),
)(IntakeRecordDetailsView);

import { FormTypes } from '../globalDestServiceConstants';
import {
  createLanguageTrainingColumns,
  createLanguageTrainingPostRequest,
  createLanguageTrainingPutRequest,
  createLanguageTrainingRows,
} from '../formData/languageTrainingFormData';
import { showToast } from 'modules/layout/layout.actions';
import { useCreateDataHandler } from '../apiHandlers/useCreateDataHandler';
import { useDeleteDataHandler } from '../apiHandlers/useDeleteDataHandler';
import { useFetchGlobalDestinationServiceDataHandler } from '../apiHandlers/useFetchDataHandler';
import { useSelector } from 'react-redux';
import { useUpdateDataHandler } from '../apiHandlers/useUpdateDataHandler';
import CrudTable from 'modules/clients/details/crudTable.component';
import React, { useRef, useState } from 'react';
import SpinnerComponent from 'modules/common/spinner.component';

const LanguageTrainingCrudTable = ({ id, family,isReadOnly }) => {
  const createDataHandler = useCreateDataHandler(showToast);
  const updateDataHandler = useUpdateDataHandler(showToast);
  const deleteDataHandler = useDeleteDataHandler(showToast);
  const dataHandler = useFetchGlobalDestinationServiceDataHandler(showToast);

  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const scrollPositionRef = useRef(0);
  const containerRef = useRef(null);

  const { globalLanguageTrainings, vendors, vendorContacts } = useSelector((state) => {
    return {
      globalLanguageTrainings: state.authorizations.authorizationDetails[id]?.authGlobal?.globalLanguageTrainings,
      vendors: state.authorizations.vendors,
      vendorContacts: state.authorizations.vendorContacts,
    };
  });

  const handleCrudOperation = async (operation, ...args) => {
    scrollPositionRef.current = window.scrollY;
    setIsLocalLoading(true);
    const response = await operation(...args);
    if (response) {
      await dataHandler.fetchData(id);
    }
    setTimeout(() => {
      window.scrollTo(0, scrollPositionRef.current);
      setIsLocalLoading(false);
    }, 0);
    return response;
  };

  const updateRow = async (originalRow, newRow) => {
    return await handleCrudOperation(
      updateDataHandler.updateData,
      FormTypes.LanguageTraining,
      createLanguageTrainingPutRequest(globalLanguageTrainings[originalRow.index], newRow, id, family),
    );
  };

  const createRow = async (newRow) => {
    return await handleCrudOperation(
      createDataHandler.createData,
      FormTypes.LanguageTraining,
      createLanguageTrainingPostRequest(newRow, id, family),
    );
  };

  const deleteRow = async (rowToDelete) => {
    return await handleCrudOperation(
      deleteDataHandler.deleteData,
      FormTypes.LanguageTraining,
      id,
      rowToDelete.original.authGlobalLanguageId,
    );
  };

  return (
    <div style={{ minHeight: '200px', position: 'relative' }} ref={containerRef}>
      {isLocalLoading ? (
        <div style={{ position: 'absolute', display: 'flex' }}>
          <SpinnerComponent />
        </div>
      ) : (
        <CrudTable
          editingMode={'modal'}
          columns={createLanguageTrainingColumns(vendors, vendorContacts, family)}
          rows={createLanguageTrainingRows(globalLanguageTrainings, vendors, vendorContacts, family)}
          updateRow={updateRow}
          createRow={createRow}
          deleteRow={deleteRow}
          customAddText={'Add Language Training'}
          hideEdit={isReadOnly}
        />
      )}
    </div>
  );
};

export default LanguageTrainingCrudTable;

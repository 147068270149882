/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */

import {
  Box, Button, Checkbox, Dialog, DialogActions, FormControl, InputLabel, Link, MenuItem, Paper, Select, Tab, Tabs, Typography,
} from '@mui/material';
import { FormControlLabel, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import queryString from 'query-string';

import { showToast } from 'modules/layout/layout.actions';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import Hint from 'modules/common/hint.component';
import Spinner from 'modules/common/spinner.component';

import { deleteEquity, getAllAppraisals, getAllListings, getEquity, postAppraisal, postEquity, postHomesaleInfo, postListing, updateEquity, updateHomesaleInfo } from 'modules/authorizations/store/actions/homesale.actions';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';

import {
  DELETE_EQUITY_FAILURE, GET_APPRAISALS_FAILURE, GET_EQUITY_FAILURE, GET_LISTINGS_FAILURE, POST_APPRAISALS_FAILURE,
  POST_EQUITY_FAILURE, POST_HOMESALE_INFO_FAILURE, POST_LISTINGS_FAILURE, UPDATE_EQUITY_FAILURE, UPDATE_HOMESALE_INFO_FAILURE,
} from 'modules/authorizations/store/types/homesaleTypes';

import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';

import AppraisalBmaDetails from './appraisalBmaDetails.component';
import BuyoutComponent from './buyout/buyout.component';
import Equity from './equity/equity.component';
import HsPrimaryInfo from './hsPrimaryInfo.component';
import InspectionsComponent from './inspections/inspections.component';
import Listings from './listings/listings.component';
import ResaleOffers from './resaleOffers.component';


const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
  headerFixed: {
    zIndex: 4,
  },
  tabFixed: { 
    paddingTop: '100px',
    zIndex: 3,
  }, 
  estimateHeaderFixed: {
    paddingTop: '150px',
    zIndex: 2,
  },
});

const validTabs = ['PRIMARY', 'APPRAISALS', 'LISTINGS', 'BUYOUT', 'RESALE', 'EQUITY', 'INSPECTIONS'];

const isValidQueryStringTab = (tab) => validTabs.includes(tab);

const getDefaultTab = (location) => {
  const tabFromQuery = queryString.parse(location.search)?.tab ?? 'PRIMARY';
  return isValidQueryStringTab(tabFromQuery) ? tabFromQuery : 'PRIMARY';
};

const HomesaleService = (props) => {    
  const { brokerAgents, details, id, location, shouldShowSideDrawer, vendors, inspectors, titleReps, isReadOnly } = props;
  const [currentTab, setCurrentTab] = useState(getDefaultTab(location));

  const [appraisalId, setAppraisalId] = useState(details?.authHomesale?.appraisalsBmas && details?.authHomesale?.appraisalsBmas[0] ? details?.authHomesale?.appraisalsBmas[0].appraisalId : null);

  const [appraisalsSelected, setAppraisalsSelected] = useState(false);
  const [showAddAppraiser, setShowAddAppraiser] = useState(false);
  const [showAddBroker, setShowAddBroker] = useState(false);

  const [selectedAppraiser, setSelectedAppraiser] = useState(null);
  const [selectedAppraiserName, setSelectedAppraiserName] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [selectedBrokerName, setSelectedBrokerName] = useState(null);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const [isOrderingList, setIsOrderingList] = useState(false);

  const [listingId, setListingId] = useState(details?.authHomesale?.listings && details?.authHomesale?.listings[0] ? details?.authHomesale?.listings[0].listingId : null);
  const [showAddListing, setShowAddListing] = useState(false);
  const [listingType, setListingType] = useState(null);

  const [showAddEquity, setShowAddEquity] = useState(false);
  const [equityStatus, setEquityStatus] = useState(null);
  const [equitySaleType, setEquitySaleType] = useState(details?.authHomesale?.homesale?.saleType ? details?.authHomesale?.homesale?.saleType : null);
  const [equityId, setEquityId] = useState(details?.authHomesale?.equities && details?.authHomesale?.equities[0] ? details?.authHomesale?.equities[0].equityId : null);
  const [showPostVoucher, setShowPostVoucher] = useState(null);
  const [batchType, setBatchType] = useState(null);
  const [notPayabale, setNotPayable] = useState(null);
  const [createAsRushPayment, setCreateAsRushPayment] = useState(null);
  const [createAsWireTransfer, setCreateAsWireTransfer] = useState(null);
  const [proceedsVendorId, setProceedsVendorId] = useState(null);
  const [proceedsVendorName, setProceedsVendorName] = useState(null);

  const typeOptions = [
    { title: 'HMA', id: 1 },
    { title: 'BVO', id: 2 },
    { title: 'GBO', id: 3 },
  ];

  const listingTypes = [
    { title: 'ORIGLIST', id: 1 },
    { title: 'EXTENSION', id: 2 },
    { title: 'PRICECHG', id: 3 },
    { title: 'RELIST', id: 4 },
  ];

  const equityStatuses = [
    { title: 'Advance', id: 1 },
    { title: 'Estimate', id: 2 },
    { title: 'Final', id: 3 },
  ];

  const searchingColumns = [{ name: 'brokerId', title: 'Id' }, { name: 'brokerCode', title: 'Code' }, { name: 'agentName', title: 'Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'zipCode', title: 'Zip Code' }];
  const searchingColumnExtensions = [{ columnName: 'brokerId', width: 150 }, { columnName: 'brokerCode', width: 250 }, { columnName: 'agentName', width: 250 }, { name: 'companyName', width: 175 }, { name: 'city', width: 175 }, { columnName: 'state', width: 175 }, { columnName: 'zipCode', width: 175 }];

  const createAppraisal = async () => {
    setIsLoadingModal(true);

    const selectedAppraiserData = brokerAgents.find((b) => b.brokerId === selectedAppraiser);

    const resp = await props.postAppraisal({
      authorizationId: details?.authDetails?.summary.authorizationId,
      appraisalBmaType: 0,
      appraiserId: selectedAppraiserData.brokerId,
      appraiserCode: selectedAppraiserData.brokerCode,
      appraiserName: selectedAppraiserData.agentName,
      appraiserGroup: selectedAppraiserData.companyName,
    });

    if (resp.type === POST_APPRAISALS_FAILURE) {
      props.showToast('Failed to create the new Appraisal, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else { 
      const allAppraisalsResp = await props.getAllAppraisals(details?.authDetails?.summary.authorizationId);
      if (allAppraisalsResp.type === GET_APPRAISALS_FAILURE) {
        props.showToast('Failed to fetch homesale appraisals, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Appraiser added successfully!', { userMustDismiss: false, severity: 'success' });
        setAppraisalId(resp.response.appraisalId);
        setShowAddAppraiser(false);
      }
      setIsLoadingModal(false);
    }
  };

  const createBma = async () => {
    setIsLoadingModal(true);

    const selectedAppraiserData = brokerAgents.find((b) => b.brokerId === selectedBroker);

    const resp = await props.postAppraisal({
      authorizationId: details?.authDetails?.summary.authorizationId,
      appraisalBmaType: 1,
      appraiserId: selectedAppraiserData.brokerId,
      appraiserCode: selectedAppraiserData.brokerCode,
      appraiserName: selectedAppraiserData.agentName,
      appraisalGroup: selectedAppraiserData.companyName,
    });

    if (resp.type === POST_APPRAISALS_FAILURE) {
      props.showToast('Failed to create the new BMA, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else { 
      const allAppraisalsResp = await props.getAllAppraisals(details?.authDetails?.summary.authorizationId);
      if (allAppraisalsResp.type === GET_APPRAISALS_FAILURE) {
        props.showToast('Failed to fetch homesale appraisals, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('BMA Added successfully!', { userMustDismiss: false, severity: 'success' });
        setAppraisalId(resp.response.appraisalId);
        setShowAddBroker(false);
      }
      setIsLoadingModal(false);
    }
  };

  const createListing = async () => {
    setIsLoadingModal(true);

    const selectedAppraiserData = brokerAgents.find((b) => b.brokerId === selectedBroker);

    const resp = await props.postListing({
      authorizationId: details?.authDetails?.summary.authorizationId,
      brokerId: selectedAppraiserData.brokerId,
      brokerCode: selectedAppraiserData.brokerCode,
      brokerName: selectedAppraiserData.agentName,
      brokerGroup: selectedAppraiserData.companyName,
      brokerEmail: selectedAppraiserData.contactEmail,
      brokerPhone: selectedAppraiserData.contactMobileNumber,
      type: listingType,
    });

    if (resp.type === POST_LISTINGS_FAILURE) {
      props.showToast('Failed to create the new listing, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else { 
      const allListingsResp = await props.getAllListings(details?.authDetails?.summary.authorizationId);
      if (allListingsResp.type === GET_LISTINGS_FAILURE) {
        props.showToast('Failed to fetch homesale listings, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Listing added successfully!', { userMustDismiss: false, severity: 'success' });
        setListingId(resp.response.listingId);
        setShowAddListing(false);
      }
      setIsLoadingModal(false);
    }
  };

  const createEquity = async () => {
    setIsLoadingModal(true);

    const resp = await props.postEquity({
      authorizationId: details?.authDetails?.summary.authorizationId,
      version: details?.authHomesale?.equities ? details?.authHomesale?.equities.length + 1 : 1,
      saleType: equitySaleType,
      status: equityStatus,
    });

    if (resp.type === POST_EQUITY_FAILURE) {
      props.showToast('Failed to create the new equity, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
    } else { 
      const allEquitiesResp = await props.getEquity(details?.authDetails?.summary.authorizationId);
      if (allEquitiesResp.type === GET_EQUITY_FAILURE) {
        props.showToast('Failed to fetch homesale equities, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Equity added successfully!', { userMustDismiss: false, severity: 'success' });
        setEquityId(resp.response.equityId);
        setShowAddEquity(false);
      }
      setIsLoadingModal(false);
    }
  };

  const deleteEquityById = async (id) => {
    const resp = await props.deleteEquity(id);
    if (resp.type === DELETE_EQUITY_FAILURE) {
      return false;
    } else {
      const getResp = await props.getEquity(details?.authDetails?.summary.authorizationId);
      if (getResp.type === GET_EQUITY_FAILURE) {
        return false;
      } else {
        setEquityId(null);
        return true;
      }
    }
  };

  const postVoucher = async () => {
    setIsLoadingModal(true);

    let newValues = details?.authHomesale?.equities.find((e) => e.equityId === equityId);

    newValues.postedOn = new Date();

    const resp = await props.updateEquity(newValues);
    if (resp.type === UPDATE_EQUITY_FAILURE) {
      props.showToast('Failed to post voucher, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoadingModal(false);
      return false;
    } else { 
      props.showToast('Voucher posted successfully!', { userMustDismiss: false, severity: 'success' });
      setEquityId(resp.response.equityId);
      setShowPostVoucher(false);
      setIsLoadingModal(false);
    }
  };

  const exportSampleFile = () => {
    let csvContent = 'data:text/csv;charset=utf-8,Sample,Equity,Report,Content,';

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Sample Homesale Equity Report ${new Date().toLocaleDateString()}.csv`);
    document.body.appendChild(link); // Required for FF
    
    link.click(); 
  };

  const orderList = async () => {
    setIsOrderingList(true);
    let newVals = { ...details?.authHomesale?.homesale };
    if (appraisalsSelected) {
      newVals.appraiserRequestSent = true;
    } else {
      newVals.brokerRequestSent = true;
    }

    if (!details?.authHomesale?.homesale || (details?.authHomesale?.homesaleInfo && details?.authHomesale?.homesale.length === 0)) {
      newVals.authorizationId = id;

      let resp = await props.postHomesaleInfo(newVals);
      if (resp.type === POST_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to send appraiser list request, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Appraiser list request sent successfully!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      }

      setIsOrderingList(false);
      return resp;
    } else {
      let resp = await props.updateHomesaleInfo(newVals);
      if (resp.type === UPDATE_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to send broker list request, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('Broker list request sent successfully!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
      }

      setIsOrderingList(false);
      return resp;
    }
  };

  const changeTab = (tab) => {
    setCurrentTab(tab);
    const values = queryString.parse(location.search);
    values.tab = tab;
    props.history.replace(`${location.pathname}?${queryString.stringify(values)}`);
  };


  useEffect(() => {
    setAppraisalId(details?.authHomesale?.appraisalsBmas && details?.authHomesale?.appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1))[0] ? details?.authHomesale?.appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1))[0].appraisalId : null);
  }, [appraisalsSelected]);

  return (
    <Box>
      <Paper
        square
        className={classNames({
          'position-fixed': !isReadOnly,
          [props.classes.headerFixed]: !isReadOnly,
          [props.classes.container]: true,
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
        sx={isReadOnly ? { paddingTop: '5px', zIndex: 4, position: 'relative'  } : {}}
      >

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Homesale Status
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details?.authDetails?.authServices.homesale}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Move Type
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details?.authDetails?.summary.moveType}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Policy
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Sale Type
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details?.authHomesale?.homesale?.saleType != null ? typeOptions.find((o) => o.id === details?.authHomesale?.homesale.saleType).title : <div>&mdash;</div>}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            {!isReadOnly && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }}> Cancel Service </Button>}
            {!isReadOnly && <Button color="secondary"variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#f0b11d', '&:hover': { backgroundColor: '#ba8a00' } }}> Set On Hold </Button>}
            {!isReadOnly && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}> Complete Service </Button>}
          </Box>
        </Box>
      </Paper>
      <Paper
        square
        className={classNames({
          'position-fixed': !isReadOnly,
          [props.classes.tabFixed]: !isReadOnly,
          [props.classes.container]: true,
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
        sx={isReadOnly ? { paddingTop: '5px', zIndex: 3, position: 'relative'  } : {}}
      >
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={(event, tab) => changeTab(tab)}
          sx={{}}
        >
          <Tab value={'PRIMARY'} label="Primary Info" />
          <Tab value={'APPRAISALS'} label="Appraisals/BMAs" />
          <Tab value={'LISTINGS'} label="Listings" />
          <Tab value={'BUYOUT'} label="Buyout" />
          <Tab value={'RESALE'} label="Resale" />
          {details?.authHomesale?.homesale.saleType !== 1 && <Tab value={'EQUITY'} label="Equity" />}
          {details?.authHomesale?.homesale.saleType !== 1 && <Tab value={'INSPECTIONS'} label="Inspections" />}
        </Tabs>
      </Paper>

      {currentTab === 'APPRAISALS' && (
        <Paper
          square
          className={classNames({
            'position-fixed': !isReadOnly,
            [props.classes.estimateHeaderFixed]: !isReadOnly,
            [props.classes.container]: true,
            [props.classes.drawerOpen]: shouldShowSideDrawer
          })}
          sx={isReadOnly ? { paddingTop: '5px', zIndex: 2, position: 'relative'  } : {}}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
                {details?.authHomesale?.homesale?.saleType !== 1 && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: appraisalsSelected ? '#36939b' : '#ffffff', '&:hover': { backgroundColor: appraisalsSelected ? '#15585e' : '#faf8f2' }, color: appraisalsSelected ? 'ffffff' : '#080600' }} onClick={() => setAppraisalsSelected(true)}> Appraisals </Button>}
                {<Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: !appraisalsSelected ? '#36939b' : '#ffffff', '&:hover': { backgroundColor: !appraisalsSelected ? '#15585e' : '#faf8f2' }, color: !appraisalsSelected ? 'ffffff' : '#080600' }} onClick={() => setAppraisalsSelected(false)}> BMAs </Button>}
              </Box>
              <Box />
            </Box>
            {!isReadOnly && appraisalsSelected && (
              <Box>
                {details?.authHomesale?.homesale?.appraiserRequestSent && !isOrderingList && <Typography color="secondary" sx={{ marginTop: '10px' }}> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Appraiser List request has been sent, <Link onClick={async () => await orderList()} sx={{ cursor: 'pointer' }}>click here</Link> to resend.</Typography>}
                {!details?.authHomesale?.homesale?.appraiserRequestSent && !isOrderingList && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginTop: '8px' }} onClick={async () => await orderList()}> Request Appraiser List </Button>}
                {isOrderingList && <Spinner />}
              </Box>
            )}
            {!isReadOnly && !appraisalsSelected && (
              <Box>
                {details?.authHomesale?.homesale?.brokerRequestSent && !isOrderingList && <Typography color="secondary" sx={{ marginTop: '10px' }}> <FontAwesomeIcon size="1x" icon={['fas', 'fa-check-circle']} color="#5D9878" sx={{ marginRight: '13px' }} /> Broker List request has been sent, <Link onClick={async () => await orderList()} sx={{ cursor: 'pointer' }}>click here</Link> to resend.</Typography>}
                {!details?.authHomesale?.homesale?.brokerRequestSent && !isOrderingList && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginTop: '8px' }} onClick={async () => await orderList()}> Request Broker List </Button>}
                {isOrderingList && <Spinner />}
              </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Select
                labelId={'appraisalId'}
                key={'appraisalId'}
                label={''}
                name={'appraisalId'}
                value={appraisalId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setAppraisalId(e.target.value)
                }
              >
                {details && details?.authHomesale?.homesale && details?.authHomesale?.appraisalsBmas && details?.authHomesale?.appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1)).map((opt) => (
                  <MenuItem key={opt.appraisalId} value={opt.appraisalId} sx={{ margin: 'dense' }}>
                    {`${opt.appraiserName} (${opt.appraiserCode})`}
                  </MenuItem>
                ))}
              </Select>
              {!isReadOnly && appraisalsSelected && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddAppraiser(true)}> New Appraiser </Button>}
              {!isReadOnly && !appraisalsSelected && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddBroker(true)}> New Broker </Button>}
            </Box>
          </Box>
        </Paper>
      )}

      {currentTab === 'LISTINGS' && (
        <Paper
          square
          className={classNames({
            'position-fixed': !isReadOnly,
            [props.classes.estimateHeaderFixed]: !isReadOnly,
            [props.classes.container]: true,
            [props.classes.drawerOpen]: shouldShowSideDrawer
          })}
          sx={isReadOnly ? { paddingTop: '5px', zIndex: 2, position: 'relative'  } : {}}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Select
                labelId={'listingId'}
                key={'listingId'}
                label={''}
                name={'listingId'}
                value={listingId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setListingId(e.target.value)
                }
              >
                {details && details?.authHomesale?.listings && details?.authHomesale?.listings.map((opt) => (
                  <MenuItem key={opt.listingId} value={opt.listingId} sx={{ margin: 'dense' }}>
                    {`${opt.brokerName} (${listingTypes.find((t) => t.id === opt.type).title})`}
                  </MenuItem>
                ))}
              </Select>
              {!isReadOnly && <Button 
                color="secondary" 
                type="submit" 
                variant="contained" 
                sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} 
                onClick={() => setShowAddListing(true)} 
                disabled={details && details?.authHomesale?.homesale && details?.authHomesale?.appraisalsBmas && details?.authHomesale?.appraisalsBmas.filter((a) => (a.appraisalBmaType === 1 && a.agentSelected)).length < 1}
              > 
                New Listing 
              </Button> }
              {!isReadOnly && details && details?.authHomesale?.homesale && details?.authHomesale?.appraisalsBmas && details?.authHomesale?.appraisalsBmas.filter((a) => (a.appraisalBmaType === 1 && a.agentSelected)).length < 1 &&
                <Box sx={{ paddingLeft: '20px', paddingTop: '20px' }}>
                  <Hint>You must have a BMA broker selected in order to add listings.</Hint>
                </Box>
              }
            </Box>
          </Box>
        </Paper>
      )}

      {currentTab === 'EQUITY' && (
        <Paper
          square
          className={classNames({
            'position-fixed': !isReadOnly,
            [props.classes.estimateHeaderFixed]: !isReadOnly,
            [props.classes.container]: true,
            [props.classes.drawerOpen]: shouldShowSideDrawer
          })}
          sx={isReadOnly ? { paddingTop: '5px', zIndex: 2, position: 'relative'  } : {}}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Select
                labelId={'equityId'}
                key={'equityId'}
                label={''}
                name={'equityId'}
                value={equityId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setEquityId(e.target.value)
                }
              >
                {details && details?.authHomesale?.homesale && details?.authHomesale?.equities && details?.authHomesale?.equities.map((opt) => (
                  <MenuItem key={opt.equityId} value={opt.equityId} sx={{ margin: 'dense' }}>
                    {`${equityStatuses.find((e) => e.id === opt.status).title} (V${opt.version})`}
                  </MenuItem>
                ))}
              </Select>
              {!isReadOnly && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddEquity(true)}> Add Equity </Button>}
              {!isReadOnly && equityId != null && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={() => { if (window.confirm(`Are you sure you would like to delete the equity ${equityId}?`)) deleteEquityById(equityId);}}> Delete Equity </Button>}
            </Box>
            <Box>
              {!isReadOnly && details?.authHomesale?.equities && details?.authHomesale?.equities.find((e) => e.equityId === equityId) && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginTop: '8px', marginRight: '10px' }} onClick={exportSampleFile}> Export Report </Button>}
              {!isReadOnly && details?.authHomesale?.equities && details?.authHomesale?.equities.find((e) => e.equityId === equityId) && details?.authHomesale?.equities.find((e) => e.equityId === equityId).postedOn === null && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginTop: '8px' }} onClick={() => setShowPostVoucher(true)}> Post Voucher </Button>}
              {isOrderingList && <Spinner />}
            </Box>
          </Box>
        </Paper>
      )}

      <Box
        sx={{
          padding: currentTab === 'INSPECTIONS' ? '5px' : '20px',
          paddingTop: isReadOnly ? '20px' : (currentTab === 'APPRAISALS' || currentTab === 'LISTINGS' || currentTab === 'EQUITY') ? '245px' : currentTab === 'INSPECTIONS' ? '153px' : '165px',
        }}
      >
        {currentTab === 'PRIMARY' && <HsPrimaryInfo details={details} id={id} isReadOnly={isReadOnly}/>}
        {currentTab === 'APPRAISALS' && <AppraisalBmaDetails details={details} id={id} appraisalId={appraisalId} appraisalsSelected={appraisalsSelected} brokerAgents={brokerAgents} isReadOnly={isReadOnly}/>}
        {currentTab === 'LISTINGS' && <Listings details={details} id={id} listingId={listingId} brokerAgents={brokerAgents} isReadOnly={isReadOnly}/>}
        {currentTab === 'BUYOUT' && <BuyoutComponent homesale={details?.authHomesale?.homesale} titleReps={titleReps} id={id} isReadOnly={isReadOnly}/>}
        {currentTab === 'RESALE' && <h1><ResaleOffers details={details} id={id} brokerAgents={brokerAgents} isReadOnly={isReadOnly}/></h1>}
        {currentTab === 'EQUITY' && <Equity details={details} id={id} equityId={equityId} isReadOnly={isReadOnly}/>}
        {currentTab === 'INSPECTIONS' && <InspectionsComponent inspectors={inspectors} brokers={brokerAgents.filter((b) => b.typeOfAgent === 'Appraiser')} vendors={vendors} id={id} inspections={details?.authHomesale?.inspections} isReadOnly={isReadOnly}/> }
      </Box>

      <Dialog open={showAddAppraiser} fullWidth={false} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box>
            <AdvancedSearch 
              labelText={'Appraiser'} 
              displayValueAccessorKey={'agentName'} 
              saveIdValueAccessorKey={'brokerId'} 
              searchingCriteria={searchingColumns} 
              searchingCriteriaExtensions={searchingColumnExtensions} 
              searchingRows={brokerAgents.filter((a) => a.typeOfAgent === 'Agent')} 
              id={selectedAppraiser} 
              value={selectedAppraiserName} 
              setIdValue={(id, value) => { setSelectedAppraiser(id); setSelectedAppraiserName(value); }}
              isReadOnly={isReadOnly}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && !isReadOnly &&
            <>
              <Button onClick={() => setShowAddAppraiser(false)}>Cancel</Button>
              <Button onClick={createAppraisal} color="primary" type="submit" variant="contained" disabled={selectedAppraiser == null}>
                Create Appraisal
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <Dialog open={showAddBroker} fullWidth={false} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box>
            <AdvancedSearch 
              labelText={'Broker'} 
              displayValueAccessorKey={'agentName'} 
              saveIdValueAccessorKey={'brokerId'} 
              searchingCriteria={searchingColumns} 
              searchingCriteriaExtensions={searchingColumnExtensions} 
              searchingRows={brokerAgents.filter((a) => a.typeOfAgent === 'Broker')} 
              id={selectedBroker} 
              value={selectedBrokerName} 
              setIdValue={(id, value) => { setSelectedBroker(id); setSelectedBrokerName(value); }}
              isReadOnly={isReadOnly}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && !isReadOnly &&
            <>
              <Button onClick={() => setShowAddBroker(false)}>Cancel</Button>
              <Button onClick={createBma} color="primary" type="submit" variant="contained" disabled={selectedBroker == null}>
                Create BMA
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <Dialog open={showAddListing} fullWidth={false} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box sx={{ marginRight: '20px' }}>
            <AdvancedSearch 
              labelText={'Broker'} 
              displayValueAccessorKey={'agentName'} 
              saveIdValueAccessorKey={'brokerId'} 
              searchingCriteria={searchingColumns} 
              searchingCriteriaExtensions={searchingColumnExtensions} 
              searchingRows={brokerAgents.filter((a) => a.typeOfAgent === 'Broker')} 
              id={selectedBroker} 
              value={selectedBrokerName} 
              setIdValue={(id, value) => { setSelectedBroker(id); setSelectedBrokerName(value); }}
              isReadOnly={isReadOnly}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          <Box>
            <FormControl sx={{ marginTop: '6px' }}>
              <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'listingType'}>Type</InputLabel>
              <Select
                labelId={'listingType'}
                key={'listingType'}
                label={'listingType'}
                name={'listingType'}
                required={true}
                disabled={isReadOnly}
                value={listingType}
                margin="dense"
                variant="standard"
                sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) => setListingType(e.target.value)}
              >
                {listingTypes.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }} disabled={isReadOnly}>
                    {opt.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && !isReadOnly &&
            <>
              <Button onClick={() => setShowAddListing(false)}>Cancel</Button>
              <Button onClick={createListing} color="primary" type="submit" variant="contained" disabled={selectedBroker == null || listingType == null}>
                Create Listing
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <Dialog open={showAddEquity} fullWidth={false} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box>
            <FormControl sx={{ marginTop: '6px' }}>
              <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'saleType'}> Sale Type</InputLabel>
              <Select
                labelId={'equitySaleType'}
                key={'equitySaleType'}
                label={'equitySaleType'}
                name={'equitySaleType'}
                required={true}
                disabled={isReadOnly}
                value={equitySaleType}
                margin="dense"
                variant="standard"
                sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) => setEquitySaleType(e.target.value)}
              >
                {typeOptions.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }} disabled={isReadOnly}>
                    {opt.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          <Box>
            <FormControl sx={{ marginTop: '6px' }}>
              <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'saleType'}>Status</InputLabel>
              <Select
                labelId={'equityStatus'}
                key={'equityStatus'}
                label={'equityStatus'}
                name={'equityStatus'}
                required={true}
                disabled={isReadOnly}
                value={equityStatus}
                margin="dense"
                variant="standard"
                sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) => setEquityStatus(e.target.value)}
              >
                {equityStatuses.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }} disabled={isReadOnly}>
                    {opt.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && !isReadOnly &&
            <>
              <Button onClick={() => setShowAddEquity(false)}>Cancel</Button>
              <Button onClick={createEquity} color="primary" type="submit" variant="contained" disabled={equityStatus == null || equitySaleType == null}>
                Create Equity
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <Dialog open={showPostVoucher} fullWidth={true} maxWidth={'lg'}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
          <Box sx={{ marginRight: '10px' }}>
            <AdvancedSearch 
              labelText={'Vendor for Proceeds'} 
              displayValueAccessorKey={'companyName'} 
              saveIdValueAccessorKey={'vendorId'} 
              searchingCriteria={[{ name: 'vendorId', title: 'Vendor Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'phone', title: 'Company Phone' }, { name: 'email', title: 'Company Email' }]} 
              searchingCriteriaExtensions={[{ columnName: 'vendorId', width: 150 }, { columnName: 'companyName', width: 250 }, { name: 'city', width: 175 }, { name: 'state', width: 175 }, { columnName: 'phone', width: 175 }, { columnName: 'email', width: 175 }]} 
              searchingRows={vendors} 
              id={proceedsVendorId} 
              value={proceedsVendorName} 
              setIdValue={(id, value) => { setProceedsVendorId(id); setProceedsVendorName(value); }}
              isReadOnly={isReadOnly}
            />
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          <Box sx={{ marginRight: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="createAsWireTransfer"
                  value={createAsWireTransfer}
                  onChange={(e) => setCreateAsWireTransfer(!createAsWireTransfer)}
                  disabled={isReadOnly}
                />
              }
              label="Create as a Wire Transfer"
            />
          </Box>
          <Box sx={{ marginRight: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="createAsRushPayment"
                  value={createAsRushPayment}
                  onChange={(e) => setCreateAsRushPayment(!createAsRushPayment)}
                  disabled={isReadOnly}
                />
              }
              label="Create as a Rush Payment"
            />
          </Box>
          <Box sx={{ marginRight: '10px' }}>
            <FormControl sx={{ marginTop: '6px' }}>
              <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'saleType'}> Batch Type</InputLabel>
              <Select
                name={'batchType'}
                required={true}
                disabled={isReadOnly}
                value={batchType}
                margin="dense"
                variant="standard"
                sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) => setBatchType(e.target.value)}
              >
                <MenuItem disabled={isReadOnly} value={'ACH/WIRE'} sx={{ margin: 'dense' }}>ACH/WIRE</MenuItem>
                <MenuItem disabled={isReadOnly} value={'AP TRACKING'} sx={{ margin: 'dense' }}>AP TRACKING</MenuItem>
                <MenuItem disabled={isReadOnly} value={'BILL TO EE - CHECK'} sx={{ margin: 'dense' }}>BILL TO EE - CHECK</MenuItem>
                <MenuItem disabled={isReadOnly} value={'BILL TO EE - INTL'} sx={{ margin: 'dense' }}>BILL TO EE - INTL</MenuItem>
                <MenuItem disabled={isReadOnly} value={'BILL TO EE - ACH/WIRE'} sx={{ margin: 'dense' }}>BILL TO EE - ACH/WIRE</MenuItem>
                <MenuItem disabled={isReadOnly} value={'BILL TO EE - TRACKING'} sx={{ margin: 'dense' }}>BILL TO EE - TRACKING</MenuItem>
                <MenuItem disabled={isReadOnly} value={'BLACKOUT'} sx={{ margin: 'dense' }}>BLACKOUT</MenuItem>
                <MenuItem disabled={isReadOnly} value={'CHECK'} sx={{ margin: 'dense' }}>CHECK</MenuItem>
                <MenuItem disabled={isReadOnly} value={'CLIENT INTEREST'} sx={{ margin: 'dense' }}>CLIENT INTEREST</MenuItem>
                <MenuItem disabled={isReadOnly} value={'CODING CORRECTION'} sx={{ margin: 'dense' }}>CODING CORRECTION</MenuItem>
                <MenuItem disabled={isReadOnly} value={'DEPOSIT'} sx={{ margin: 'dense' }}>DEPOSIT</MenuItem>
                <MenuItem disabled={isReadOnly} value={'EM TRACKING'} sx={{ margin: 'dense' }}>EM TRACKING</MenuItem>
                <MenuItem disabled={isReadOnly} value={'FEE'} sx={{ margin: 'dense' }}>FEE</MenuItem>
                <MenuItem disabled={isReadOnly} value={'INTERNATIONAL'} sx={{ margin: 'dense' }}>INTERNATIONAL</MenuItem>
                <MenuItem disabled={isReadOnly} value={'JOUNRAL ENTRY'} sx={{ margin: 'dense' }}>JOUNRAL ENTRY</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          <Box>
            <FormControl sx={{ marginTop: '6px' }}>
              <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={'saleType'}>Not Payable</InputLabel>
              <Select
                name={'notPayable'}
                required={true}
                disabled={isReadOnly}
                value={notPayabale}
                margin="dense"
                variant="standard"
                sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) => setNotPayable(e.target.value)}
              >
                <MenuItem disabled={isReadOnly} value={'N/A'} sx={{ margin: 'dense' }}>N/A</MenuItem>
                <MenuItem disabled={isReadOnly} value={'Backout / Re-Enter'} sx={{ margin: 'dense' }}>Backout / Re-Enter</MenuItem>
                <MenuItem disabled={isReadOnly} value={'Cost Avoidance'} sx={{ margin: 'dense' }}>Cost Avoidance</MenuItem>
                <MenuItem disabled={isReadOnly} value={'Paid by Client Through Payroll'} sx={{ margin: 'dense' }}>Paid by Client Through Payroll</MenuItem>
                <MenuItem disabled={isReadOnly} value={'Tracking'} sx={{ margin: 'dense' }}>Tracking</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="caption" display="block" color="primary">
              Required
            </Typography>
          </Box>
          
        </Box>
        <DialogActions sx={{ p: '1.25rem' }}>
          {isLoadingModal && <Spinner />}
          {!isLoadingModal && !isReadOnly &&
            <>
              <Button onClick={() => setShowPostVoucher(false)}>Cancel</Button>
              <Button onClick={postVoucher} color="primary" type="submit" variant="contained" disabled={batchType == null || notPayabale == null || proceedsVendorId == null}>
                Post
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </Box>
  );

};

HomesaleService.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    showToast,
    postAppraisal,
    getAllAppraisals,
    postHomesaleInfo,
    updateHomesaleInfo,
    postListing,
    getAllListings,
    postEquity,
    getEquity,
    updateEquity,
    deleteEquity,
  }),
)(HomesaleService);